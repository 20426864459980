const planService = {
	id: 'planService',
	selectionType: 'single',
	label: 'actions.technicalnauticalservice.plan',
	functionality: 'ACTION_PLAN_SERVICES',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			const editableStatus = 'RQ';
			const isEditable = registries[0].servicestatusid
				? registries[0].servicestatusid === editableStatus
				: registries[0].tnsstatuscode === editableStatus;

			if (registries[0] && isEditable) {
				//LLAMAR AL LOCALSTORA
				let statusAp = window.localStorage.getItem('workingTechnicalnauticalservicestatusStatus');
				return statusAp.includes('AU');
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-planService-' + model.name + '-show', row);
	}
};

const estimateService = {
	id: 'estimateService',
	selectionType: 'single',
	label: 'actions.technicalnauticalservice.estimate',
	functionality: 'ACTION_ESTIMATE_SERVICES',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			const editableStatus = ['RQ', 'AU'];
			const isEditable = editableStatus.some((element) =>
				registries[0].servicestatusid ? element === registries[0].servicestatusid : element === registries[0].tnsstatuscode
			);
			if (registries[0] && isEditable) {
				//LLAMAR AL LOCALSTORA
				let statusAp = window.localStorage.getItem('workingTechnicalnauticalservicestatusStatus');
				return statusAp.includes('PL');
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-estimateService-' + model.name + '-show', row);
	}
};

const initiateService = {
	id: 'initiateService',
	selectionType: 'single',
	label: 'actions.technicalnauticalservice.initiate',
	functionality: 'ACTION_INITIATE_SERVICES',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			const editableStatus = ['RQ', 'AU', 'PL'];
			const isEditable = editableStatus.some((element) =>
				registries[0].servicestatusid ? element === registries[0].servicestatusid : element === registries[0].tnsstatuscode
			);

			if (registries[0] && isEditable) {
				//LLAMAR AL LOCALSTORA
				let statusAp = window.localStorage.getItem('workingTechnicalnauticalservicestatusStatus');
				return statusAp.includes('IN');
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-initiateService-' + model.name + '-show', row);
	}
};

const endService = {
	id: 'endService',
	selectionType: 'single',
	label: 'actions.technicalnauticalservice.end',
	functionality: 'ACTION_END_SERVICES',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			const editableStatus = 'IN';
			const isEditable = registries[0].servicestatusid
				? registries[0].servicestatusid === editableStatus
				: registries[0].tnsstatuscode === editableStatus;

			if (registries[0] && isEditable) {
				//LLAMAR AL LOCALSTORA
				let statusAp = window.localStorage.getItem('workingTechnicalnauticalservicestatusStatus');
				return statusAp.includes('FI');
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-endService-' + model.name + '-show', row);
	}
};

const cancelService = {
	id: 'cancelService',
	selectionType: 'single',
	label: 'actions.technicalnauticalservice.cancel',
	functionality: 'ACTION_CANCEL_SERVICES',
	checkAvailability: function (registries) {
		if (registries.length > 0) {
			const editableStatus = ['RQ', 'AU', 'PL'];
			const isEditable = editableStatus.some((element) =>
				registries[0].servicestatusid ? element === registries[0].servicestatusid : element === registries[0].tnsstatuscode
			);
			if (registries[0] && isEditable) {
				let statusAp = window.localStorage.getItem('workingTechnicalnauticalservicestatusStatus');
				return statusAp.includes('AN');
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	runAction: function (action, model, registries) {
		// to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-cancelService-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [planService, estimateService, initiateService, endService, cancelService],
	formactions: [planService, estimateService, initiateService, endService, cancelService]
};
